import React from "react"
import styled from "styled-components"

import SEO from "../components/seo"

import Typing from "react-typing-animation"

const StyledBackground = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: ${props => props.theme.colors.blue};
  overflow: hidden;
  position: relative;
  background-size: 200% 200%;

  -webkit-animation: Gradient 12s ease infinite;
  -moz-animation: Gradient 12s ease infinite;
  -o-animation: Gradient 12s ease infinite;
  animation: Gradient 12s ease infinite;

  @-webkit-keyframes Gradient {
    0% {
      background-position: 0% 51%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 51%;
    }
  }
  @-moz-keyframes Gradient {
    0% {
      background-position: 0% 51%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 51%;
    }
  }
  @-o-keyframes Gradient {
    0% {
      background-position: 0% 51%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 51%;
    }
  }
  @keyframes Gradient {
    0% {
      background-position: 0% 51%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 51%;
    }
  }
`

const StyledText = styled.div`
  width: 600px;
  margin-left: 10%;
  position: absolute;
  top: calc((100vh - 330px) / 2);

  @media (min-device-width: 320px) and (max-device-width: 667px) {
    top: calc((100vh - 195px) / 2);
  }
`

const StyledTextLineWhite = styled.span`
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.fonts.baskerville};
  font-weight: bold;
  font-size: 85px;
  line-height: 1.3;

  @media (min-device-width: 320px) and (max-device-width: 667px) {
    font-size: 50px;
  }
`

const StyledTextLineBlack = styled.span`
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fonts.baskerville};
  font-weight: bold;
  font-size: 90px;
  line-height: 1.3;

  & span {
    font-family: ${props => props.theme.fonts.overpass};
    line-height: 1.3;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 667px) {
    font-size: 50px;
  }
`

const HomePage = () => {
  setTimeout(function () {
    window.location = "/about"
  }, 5000)

  return (
    <>
      <SEO title="Software House" />
      <StyledBackground>
        <StyledText>
          <Typing speed={90}>
            <StyledTextLineWhite>We Design.</StyledTextLineWhite>
            <br />
            <StyledTextLineWhite>We Code.</StyledTextLineWhite>
            <br />
            <StyledTextLineBlack>
              We <span>Ascend</span>.
            </StyledTextLineBlack>
          </Typing>
        </StyledText>
      </StyledBackground>
    </>
  )
}

export default HomePage
